import { useState, useRef } from "react";
import axios from "axios";
import { getAuthorization } from "src/utils/getAuthorization";
import useToast from "src/hooks/useToast";
import { baseURL } from "src/constants/apiBaseUrl";
import { useLocation } from "react-router-dom";
import { adminService } from "src/api/services/admin";

// **** logic needs update. we have to consider api call. generating order id and sending list of id and listOrder of an image to components
export const useImagePicker = (refresh) => {
  const location = useLocation();
  const imagePickerRef = useRef();

  const { toast } = useToast();
  const [images, setImages] = useState([]);
  const [imageId, setImageId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [singlePicker, setSinglePicker] = useState(false);

  //**** this is for uploading new images
  const handleSelectImage = async (file, target, targetId, addNew) => {
    if (loading && singlePicker) return;
    //******** handle send image file to server after upload finished and wait till it give you an ID of the image
    const authorization = getAuthorization();
    const config = {
      onUploadProgress: function () {
        //****** let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: authorization,
      },
    };

    const imageFile = file;
    const imageURL = URL.createObjectURL(imageFile);
    const formData = new FormData();
    formData.append("files", imageFile);
    !addNew && formData.append("target", target);
    !addNew && formData.append("targetId", targetId);

    try {
      setLoading(true);
      const result = await axios.post(
        baseURL + "admin/media/upload",
        formData,
        config
      );
      if (result) {
        setLoading(false);
        setImages((images) => [
          ...images,
          {
            url: imageURL,
            file: file,
            id: addNew ? result.data.data?.targetId : result.data.data.id,
          },
        ]);
        setImageId(addNew ? result.data.data?.targetId : result.data.data.id);
        if (location.pathname.includes("me") && refresh) {
          refresh();
        }
      }
    } catch (error) {
      toast("Image size is too large!", "error");
      setLoading(false);
    }
  };

  //**** this is for images came from api and set on form update
  const handleSetImage = (image) => {
    if (!image) return;
    setImages((images) => [
      ...images,
      {
        url: image.url || image.path,
        file: "",
        id: image.id,
      },
    ]);
  };

  //**** this is for delete some image */
  const onDeleteImage = (id) => {
    let tempImages = images.map((obj) => ({ ...obj }));

    tempImages = tempImages.filter((tempImage) => tempImage.id !== id);
    setImages(tempImages);
  };

  const handleDeleteMedia = async (mediaId) => {
    const response = await adminService.deleteMedia(mediaId);
    toast("Image deleted successfully", "success");
  };

  return {
    images,
    imagePickerRef,
    imageId,
    imagePickerLoading: loading,
    handleSelectImage: handleSelectImage,
    handleSetImage: handleSetImage,
    onDeleteImage: onDeleteImage,
    setSinglePicker: setSinglePicker,
    handleDeleteMedia: handleDeleteMedia,
  };
};
